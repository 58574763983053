import React from "react";
import css from './slideTypeN.module.scss';
import {renderImageByFileName} from "../../componentsUtils";
import {viewports} from "../../componentsUtils";
import useBreakpoint from "../../hooks/useBreakpoint";
import CoveredVideo from "../CoveredVideo/CoveredVideo";

const SlideTypeN = props => {
  const {
      title = '',
      description = '',
      image1,
      videoPosition = 'left',
      image1Type = '', // vector | pixel
      image1Style = {},
      image1Mobile,
      image1MobileType = '', // vector | pixel
      imagesFluid = [],
      line = true,
      videoSrcURL,
      placeholderImage,
      placeholderImageAlt = ''
  } = props;

  const breakpoint = useBreakpoint();

  const chartPositionClass = videoPosition === 'right' ? css.videoOnRight : css.videoOnLeft;

  const renderImage = (image, imageType, viewport) => {
      switch (imageType) {
          case 'vector':
              return (
                <div className={css.vectorImageWrapper}>
                    <img src={image} alt="" style={image1Style}/>
                </div>
              );
          case 'pixel':
              return (
                <div className={css.pixelImageWrapper}>
                    <div className={css.gatsbyImageWrapper} style={image1Style}>
                        {renderImageByFileName(imagesFluid, image, '')}
                    </div>
                </div>
              );
          default:
              return null;
      }
  };

  const renderVideo = () => {
    return (
        <div className={css.videoWrapper}>
            <CoveredVideo
                videoSrcURL={videoSrcURL}
                videoWidth={'90%'}
                placeholderImage={placeholderImage}
                placeholderImageAlt={placeholderImageAlt}
                imagesFluid={imagesFluid}
                imagePosition={'right'}
                background={false}
            />
        </div>
    );
  };

  const lineClass = line ? css.line : '';

  const isDesktop = () => breakpoint.viewport === viewports.desktopViewport;

  return (
    <div className={css.wrapper}>
        <div className={[css.container, chartPositionClass].join(' ')}>
            {renderVideo()}
            <div className={[css.contentWrapper, lineClass].join(' ')}>
                { title && <div className={css.title}>{title}</div>}
                <div className={css.description}>
                    {description}
                </div>
            </div>
        </div>
            {isDesktop() && renderImage(image1, image1Type)}
            {!isDesktop() && renderImage(image1Mobile, image1MobileType)}
    </div>
  )
};

export default SlideTypeN;