import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import {useTranslation} from 'react-i18next';
import css from './use-cases.module.scss';
import Footer from "../components/shared/Footer/Footer";
import SlideTypeN from "../components/shared/ImageSlider/SlideTypeN";
import {getImagesFluid} from "../components/componentsUtils";
import useBreakpoint from "../components/hooks/useBreakpoint"
import {viewports} from "../components/componentsUtils"
import SlideTypeM from "../components/shared/ImageSlider/SlideTypeM";
import GoToYouTube from "../components/shared/goToYouTube/GoToYouTube";
import {getRoutes} from '../components/shared/routes';
import Main_2 from "../components/pages/home/partials/main/Main_2";
import DcoderHandTool from "../images/UseCases/Dcoder hand tool.jpg";
import TimberFingerprintApp1 from "../images/UseCases/TimberFingerprint App 1.jpg";
import TimberFingerprintApp2 from "../images/UseCases/TimberFingerprint App 2.jpg";
import TimberFingerprintAppProduction1 from "../images/UseCases/Example for TimberFingerprint App @ Production 1.jpg";
import TimberFingerprintAppProduction2 from "../images/UseCases/Example for TimberFingerprint App @ Production 2.jpg";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_UseCases"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const UseCasesPage = () => {
    const {t} = useTranslation();
    const pageTitle = `${getRoutes().UseCases.pageTitle}`;

    const breakpoint = useBreakpoint();

    const getImageHeight = () => {
        switch (breakpoint.viewport) {
            case viewports.phoneViewport:
                return 260;
            case viewports.tabletViewport:
                return 420;
            default:
                return 500;
        }
    };

    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const isPhone = () => breakpoint.viewport === viewports.phoneViewport;

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <div className={css.wrapper}>
                <div className={css.Main}>
                    <Main_2/>
                </div>

                <div className={css.section2}>
                    <SlideTypeM
                        title="How does TimberFingerprint work?"
                        description={<>The multidimensional AI image processing standardisation and further analysis
                            considers the unique characteristics of each timber ring surface including the distinct
                            unique points, patterns, marks, size, shape, diameter, proportions and reference markings,
                            finally <span
                                style={{color: 'var(--mainGreenColor)'}}>creating a visual TimberFingerprint.</span></>}
                        image1={'How does TimberFingerprint work.jpg'}
                        image1Mobile={'how_does_timberfingerprint_work_mobile.jpg'}
                        image1Type={'pixel'}
                        imagesFluid={imagesFluid}
                    />
                </div>

                <div className={css.section3}>
                    <SlideTypeM
                        title="TimberFingerptint functionality"
                        image1={'TimberFingerptint functionality.jpg'}
                        image1Mobile={'timberfingerptint_functionality_mobile.jpg'}
                        image1Type={'pixel'}
                        imagesFluid={imagesFluid}
                    />
                    <div className={css.description}>The web interface allows to view every log with all unique
                        authentication and inventory data.
                    </div>
                </div>


                <div className={css.section4}>
                    <div className={css.title2}>Forest owner management objectives</div>
                    <SlideTypeN
                        title="Complete digital timber logging data:"
                        description={(
                            <div style={{marginLeft: '20px'}}>
                                <ul>
                                    <li>Log authentication data</li>
                                    <li>Log inventory data collection</li>
                                    <li>Process flow automation</li>
                                </ul>
                            </div>
                        )}
                        imagesFluid={imagesFluid}
                        placeholderImage={'Forest owner management objectives.jpg'}
                        placeholderImageAlt={'Forest owner management objectives'}
                        line={false}
                    />
                </div>


                <div className={css.section5}>
                    <div className={css.title}>Example of TimberFingerprint App @ Forest street</div>
                    <div className={css.row}>
                        <div className={css.column}>
                            <div className={css.imageWrapper} style={{marginTop: '50px'}}>
                                <img src={DcoderHandTool} alt={'Dcoder Hand Tool'}/>
                            </div>
                            <div className={css.descriptionSection5}>
                                The Dcoder hand tool is generation a personalized code applied directly on a log
                            </div>
                        </div>
                        <div className={css.column}>
                            <div className={css.imageWrapper} style={{marginTop: '80px', marginBottom: '60px'}}>
                                <img src={TimberFingerprintApp1} alt={'Timber Fingerprint App 1'}/>
                            </div>
                            <div className={css.descriptionSection5}>
                                <span style={{color: 'var(--mainGreenColor)'}}>TimberFingerprint </span> App is
                                generating reference image source and a log m3 measurement automation considering both
                                ring diameters and log length
                            </div>
                        </div>
                        <div className={css.column}>
                            <div className={css.imageWrapper} style={{marginTop: '40px'}}>
                                <img src={TimberFingerprintApp2} alt={'Timber Fingerprint App 2'}/>
                            </div>
                            <div className={css.descriptionSection5}>
                                <span style={{color: 'var(--mainGreenColor)'}}>TimberFingerprint </span> App is
                                generating an image source for upload inventory count and further traceability
                            </div>
                        </div>
                    </div>
                </div>


                <div className={css.section6}>
                    <div className={css.title}>Example for TimberFingerprint App @ Production</div>
                    <div className={css.row}>
                        <div className={css.column}>
                            <div className={css.imageWrapper2}>
                                <img src={TimberFingerprintAppProduction1}
                                     alt={'Example for TimberFingerprint App @ Production 1'}/>
                            </div>
                            <div className={css.descriptionSection6}>
                                At the entry of goods into stock, sorting line unload every log is identified and the
                                data are transferred to the local inventory system
                            </div>
                        </div>
                        <div className={css.column}>
                            <div className={css.imageWrapper2} style={{marginBottom: "30px"}}>
                                <img src={TimberFingerprintAppProduction2}
                                     alt={'Example for TimberFingerprint App @ Production 2'}/>
                            </div>
                            <div className={css.descriptionSection6}>
                                At the sawmill production further traceability from a log to a board level is set by a
                                customized workflow process
                            </div>
                        </div>
                    </div>
                </div>


                <div className={css.section7}>
                    <SlideTypeM
                        title="Timber log inventory data"
                        image1={'Timber log inventory data.jpg'}
                        image1Mobile={'timber_log_inventory_data_mobile.jpg'}
                        image1Type={'pixel'}
                        imagesFluid={imagesFluid}
                    />
                    <div className={css.description} style={{marginTop: '50px'}}>
                        <div>In case of measurement and conversion
                            of metric tonnes to cubic meters is not needed, all relevant log data are set in the system.
                        </div>
                        <div>Applicable to regions where this conversion takes place (UK, Ireland, North America)
                        </div>
                    </div>
                </div>


                <div className={css.section8}>
                    <div className={css.title2}>Why TimberFingerprint?</div>
                    <SlideTypeN
                        // title="Complete digital timber logging data:"
                        description={(
                            <><p><span style={{color: 'var(--mainGreenColor)'}}> TimberFingerprint </span> delivers
                                unique fraud and tamper proof technology for timber provenance authentication and
                                accurate traceability throughout the supply chain. </p> <p
                                style={{marginTop: '40px'}}> A comprehensive solution for sustainable forest resource
                                management and meeting timber market regulations in terms of certification, chain of
                                custody, as well as carbon footprint monitoring.
                            </p></>
                        )}
                        imagesFluid={imagesFluid}
                        videoPosition={'right'}
                        placeholderImage={'TimberFingerprint App.jpg'}
                        placeholderImageAlt={'TimberFingerprint App'}
                        line={false}
                    />
                </div>


                <div className={css.GoToYouTube}>
                    <div className={css.GoToYouTubeInner}>
                        <GoToYouTube
                            externalPageUrl={'https://www.youtube.com/watch?v=ntQQslhFKww&list=PLrYEunNGWNN3sn2a03Mv7ynen7dwoyBF8'}
                        />
                    </div>
                </div>

                <div className={css.Footer}>
                    <Footer/>
                </div>

            </div>
        </Layout>
    );
};

export default UseCasesPage;
