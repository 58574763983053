import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import css from './goToYouTube.module.scss';
import YouTubeIcon from "../../../images/icons/yt_w.svg";
import GoToButton from "../GoToButton/GoToButton";

const GoToYouTube = (props) => {
  const { externalPageUrl } = props;
  const queryResult = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            socialLinks {
              youtube
            }
          }
        }
      }
    `
  );

  const { site } = queryResult;
  const socialLinks = site.siteMetadata.socialLinks;

  return (
    <div className={css.wrapper}>
        <div className={css.content}>
            <div className={css.text}>
              See more videos on our Youtube channel
            </div>
            <GoToButton
                externalPageUrl={externalPageUrl || socialLinks.youtube}
                icon={YouTubeIcon}
                text="Go to YouTube"
            />
        </div>
    </div>
  )
};

export default GoToYouTube;
