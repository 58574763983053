import React from "react";
import {Link} from 'gatsby';
import css from './goToButton.module.scss';
import MainButton from "../MainButton/MainButton";


const GoToButton = props => {
  const {
      pageUrl,
      sectionUrl,
      externalPageUrl,
      text = "Learn more",
      filled = true,
      icon = null
  } = props;

  if (!pageUrl && !sectionUrl && !externalPageUrl) {
      return null;
  }

  const renderIconWrapper = () => {
    if (!icon) {
      return null;
    }
    return (
      <div className={css.iconWrapper}>
        <img src={icon} />
      </div>
    );
  };

  const label = (<div className={css.label}>{renderIconWrapper()}{text}</div>);

  const mainButtonProps = {
      label,
      color: 'green',
      filled,
      className: css.mainButton
  }

  if (externalPageUrl) {
      return (
          <div className={css.wrapper}>
              <MainButton
                  href={externalPageUrl}
                  {...mainButtonProps}
              />
          </div>
      );
  }

  if (sectionUrl) {
      return (
          <div className={css.wrapper}>
              <a href={sectionUrl} className={css.button}>
                  <MainButton
                      isLink={false}
                      {...mainButtonProps}
                  />
              </a>
          </div>
      )
  }

  return (
      <div className={css.wrapper}>
          <Link to={pageUrl} className={css.button}>
              <MainButton
                  isLink={false}
                  {...mainButtonProps}
              />
          </Link>
      </div>
  )
};

export default GoToButton;