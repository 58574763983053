import React from "react";
import css from './slideTypeM.module.scss';
import {renderImageByFileName} from "../../componentsUtils";
import useBreakpoint from "../../hooks/useBreakpoint"
import {viewports} from "../../componentsUtils"

const SlideTypeM = props => {
  const {
      title = '',
      description = '',
      image1,
      image1Mobile,
      image1Type = '', // vector | pixel
      image1Style = {},
      imagesFluid = []
  } = props;

  const breakpoint = useBreakpoint();

  const image = breakpoint.viewport === viewports.desktopViewport ? image1 : image1Mobile;

  const renderImage = () => {
      switch (image1Type) {
          case 'vector':
              return (
                <div className={css.image1Wrapper}>
                    <img src={image} alt="" style={image1Style}/>
                </div>
              );
          case 'pixel':
              return (
                <div className={css.image2Wrapper}>
                    <div className={css.gatsbyImageWrapper} style={image1Style}>
                        {renderImageByFileName(imagesFluid, image, '')}
                    </div>
                </div>
              );
          default:
              return null;
      }
  };

  return (
    <div className={css.wrapper}>
        <div className={css.container}>
            { title && <div className={css.title}>{title}</div>}
            <div className={css.contentWrapper}>
                <div className={css.description}>
                    {description}
                </div>
            </div>
            {renderImage()}
        </div>
    </div>
  )
};

export default SlideTypeM;