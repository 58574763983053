import React from "react";
import css from './main_2.module.scss';
import MainImage from "../../../../../images/UseCases/Uses cases hero.jpg";
import {useTranslation} from "react-i18next";
import GoToButton from "../../../../shared/GoToButton/GoToButton";
import Whitepaper from "../../../../../images/home/main/download.svg";
import TimberLogo from "../../../../../images/logos/TimberFingerprint_logo.svg";

const Main_2 = () => {
    const {t} = useTranslation();
    return (
        <div className={css.wrapper}>
            <div className={css.contentWrapper}>
                <div className={css.textWrapper}>
                    <img src={TimberLogo} alt="" className={css.TimberFingerprintLogo}/>
                    <div className={css.title}>
                    </div>
                    <div className={css.text}><p>Every tree is unique</p><p>Environmental sustainability for the climate
                        control and the carbon credit endorsement</p></div>
                    <GoToButton
                        externalPageUrl={"/files/Whitepaper_TimberFingerprint.pdf"}
                        text='Whitepaper'
                        icon={Whitepaper}
                    />
                </div>
                <div className={css.imageWrapper}>
                    <img src={MainImage} alt='Every tree is unique'/>
                </div>
            </div>
        </div>
    )
};

export default Main_2;
